export default {
  methods: {
    data() {
      return {
        userInfo: {},
      }
    },
    pemission(name) {
      switch (name) {
        case 'Lilly':
        case 'YingQin':
        case 'YanLu':
        case 'Lindsey':
          return false;
        default:
          return true;
      }
    },
    /**
     * @name 时间格式化
     * @param {时间} time 
     * @param {时间格式-默认格式：年-月-日 时：分：面} format 
     */
    formatTime(time, format) {
      // let format = format || "yyyy-MM-dd hh:mm:ss";
      Date.prototype.Format = function (fmt) {
        var o = {
          "M+": this.getMonth() + 1, // 月份
          "d+": this.getDate(), // 日
          "h+": this.getHours(), // 小时
          "m+": this.getMinutes(), // 分
          "s+": this.getSeconds(), // 秒
          "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
          S: this.getMilliseconds()
          // 毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };
      return new Date(time).Format(format || "yyyy-MM-dd hh:mm:ss");
    },
    getLocalNowTime(){
      return this.getLocalTime(-7)
    },
    getLocalTime(i) {
      //参数i为时区值数字，比如北京为东八区则输进8,纽约为西5区输入-5
      if (typeof i !== "number") return;
      var d = new Date();
      //得到1970年一月一日到现在的秒数
      var len = d.getTime();
      //本地时间与GMT时间的时间偏移差
      var offset = d.getTimezoneOffset() * 60000;
      //得到现在的格林尼治时间
      var utcTime = len + offset;
      return new Date(utcTime + 3600000 * i);
    },
    getSelectLocalTime(i,selectDate) {
      //参数i为时区值数字，比如北京为东八区则输进8,纽约为西5区输入-5
      if (typeof i !== "number") return;
      var d = selectDate;
      //得到1970年一月一日到现在的秒数
      var len = d.getTime();
      //本地时间与GMT时间的时间偏移差
      var offset = d.getTimezoneOffset() * 60000;
      //得到现在的格林尼治时间
      var utcTime = len + offset;
      return new Date(utcTime + 3600000 * i);
    },
    formatDate(params) {
      let defalutParams = {
        date: new Date(),
        formatStr: "MM/dd/yyyy",
      };
      params = { ...defalutParams, ...params };
      let date = params.date;
      let formatStr = params.formatStr;
      // 传入日期字符串 - 转成时间戳 - 转成标准时间
      let timeStamp = new Date(date).getTime();
      date = new Date(timeStamp);
      formatStr = formatStr.replace(
        new RegExp("yyyy"),
        `${date.getFullYear()}`
      );
      const month = date.getMonth() + 1;
      formatStr = formatStr.replace(
        new RegExp("MM"),
        `${month > 9 ? month : "0" + month}`
      );
      const day = date.getDate();
      formatStr = formatStr.replace(
        new RegExp("dd"),
        `${day > 9 ? day : "0" + day}`
      );
      const hour = date.getHours();
      formatStr = formatStr.replace(
        new RegExp("HH"),
        `${hour > 9 ? hour : "0" + hour}`
      );
      const min = date.getMinutes();
      formatStr = formatStr.replace(
        new RegExp("mm"),
        `${min > 9 ? min : "0" + min}`
      );
      const sec = date.getSeconds();
      formatStr = formatStr.replace(
        new RegExp("ss"),
        `${sec > 9 ? sec : "0" + sec}`
      );
      return formatStr;
    },
    /**
     * @name 添加日期
     * @param 要添加的时间 dayNumber 
     * @param 默认当前时间 date 
     * @param 默认：day，year、month、day、week、hour、minute、secord 
     */
    addDate(dayNumber, date, type) {
      date = date ? date : new Date();
      var ms = 0;
      switch (type) {
        case "year":
          ms = dayNumber * 365 * (1000 * 60 * 60 * 24)
          break;
        case "month":
          ms = dayNumber * 30 * (1000 * 60 * 60 * 24)
          break;
        case "week":
          ms = dayNumber * 7 * (1000 * 60 * 60 * 24)
          break;
        case "day":
          ms = dayNumber * (1000 * 60 * 60 * 24)
          break;
        case "hour":
          ms = dayNumber * 1000 * 60 * 60
          break;
        case "minute":
          ms = dayNumber * 1000 * 60
          break;
        case "secord":
          ms = dayNumber * 1000
          break;
        default:
          break;
      }
      var newDate = new Date(date.getTime() + ms);
      return newDate;
    }
  },
  created() {
    let specUserInfo =  JSON.parse(localStorage.getItem('specUserInfo'))
    console.info('specUserInfospecUserInfo--:',specUserInfo)
    if(specUserInfo){
      this.userInfo = specUserInfo;
    }
    else{
      this.userInfo = {
        username:'',
        operateStatus:false
      }
    }
  }
}